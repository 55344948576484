// dynamic formset for creating positions
function hook_add_form_button(): void {
    const addButton = document.querySelector("#button-id-add-form")
    const listing_formset = document.getElementById("listing-formset")
    if (listing_formset !== null && addButton !== null) {
        const listing_forms = listing_formset.querySelectorAll(".listing-row")
        const formActions = listing_formset.querySelector(".form-actions")
        const totalForms = listing_formset.querySelector("#id_listings-TOTAL_FORMS")
        let formNum = listing_forms.length - 1
        const idRegex = RegExp("listings-(\\d+)-", 'g')

        addButton.addEventListener('click', (event): void => {
            event.preventDefault()

            formNum++
            const newForm = <Element>listing_forms[0].cloneNode(true)
            newForm.innerHTML = newForm.innerHTML.replace(idRegex, `listings-${formNum}-`)

            for (const select of newForm.querySelectorAll("select")) {
                select.value = ""
            }

            for (const input of newForm.querySelectorAll("input")) {
                input.value = ""
            }
            listing_formset?.insertBefore(newForm, formActions)
            totalForms?.setAttribute('value', `${formNum + 1}`)
        })
    }
}

window.addEventListener('load', hook_add_form_button);
