// https://stackoverflow.com/a/60291224/13138364
function animate_counter(counter: Element, start: number, end: number, duration: number) {
    const startTimestamp = performance.now();

    const step: FrameRequestCallback = (timestamp: DOMHighResTimeStamp) => {
        // Percentage of animation time elapsed
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        // Get the current integer value
        const value = Math.floor(progress * (end - start) + start);
        counter.innerHTML = value.toString()

        if (progress < 1) {
            window.requestAnimationFrame(step);
        }
    };
    window.requestAnimationFrame(step);
}


// https://stackoverflow.com/a/62537274/13138364
const animated = {};
const options = {
    rootMargin: '0px',
    threshold: 0.5,
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
        if (!animated[entry.target.id] && (entry.intersectionRatio > options.threshold)) {
            animated[entry.target.id] = true;
            animate_counter(entry.target, 10, parseInt(entry.target.innerHTML), 1000);
        }
    });
}, options);

window.addEventListener('load', () => {
    const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches
    const selectors = '#offer-counter, #faculty-counter, #listing-counter';

    if (!prefersReducedMotion) {
        document.querySelectorAll(selectors).forEach(selector => {
            observer.observe(selector);
        });
    }
})
