<script lang="ts">
    import { writable } from 'svelte/store'
    import { reduxify } from "svelte-reduxify"

    export let job_levels = [{val: "default", id: 0}]
    export let exp_levels = [{val: "default", id: 0}]
    export let default_rate = 10.00
    export let rates = [ [ default_rate ] ]
    export let savestores = import.meta.env.DEV

    if (savestores) {
        let saved_job_levels = JSON.parse(window.sessionStorage.getItem("job_levels"))
        let saved_exp_levels = JSON.parse(window.sessionStorage.getItem("exp_levels"))
        let saved_rates = JSON.parse(window.sessionStorage.getItem("rates"))

        if (saved_rates.length === saved_job_levels.length && saved_rates[0].length === saved_exp_levels.length) {
            job_levels = saved_job_levels || job_levels
            exp_levels = saved_exp_levels || exp_levels
            rates = saved_rates || rates
        }
    }

    let job_level_store = writable(job_levels)
    let exp_level_store = writable(exp_levels)
    let rates_store = writable(rates)

    if (import.meta.env.DEV) {
        job_level_store = reduxify(job_level_store)
        exp_level_store = reduxify(exp_level_store)
        rates_store = reduxify(rates_store)
    }

    $: if (savestores && $job_level_store && $exp_level_store && $rates_store) {
        window.sessionStorage.setItem("job_levels", JSON.stringify($job_level_store))
        window.sessionStorage.setItem("exp_levels", JSON.stringify($exp_level_store))
        window.sessionStorage.setItem("rates", JSON.stringify($rates_store))
    }

    function addJobLevel(newVal='') {
        job_level_store.update(vals => {
            let nextId = Math.max(0, ...vals.map(x => x.id)) + 1;
            return [...vals, {val: newVal, id: nextId}]
        })
        rates_store.update(vals => {
            let new_vals = []
            for (const row of vals) {
                new_vals.push([...row])
            }
            new_vals.push(Array($exp_level_store.length).fill(default_rate))
            return new_vals
        })
    }

    function addExpLevel(newVal='') {
        exp_level_store.update(vals => {
            let nextId = Math.max(0, ...vals.map(x => x.id)) + 1;
            return [...vals, {val: newVal, id: nextId}]
        })
        rates_store.update(vals => {
            let new_vals = []
            for (const row of vals) {
                new_vals.push([...row, default_rate])
            }
            return new_vals
        })
    }

    function removeJobLevel(index: number) {
        job_level_store.update(vals => {
            let new_vals = Array.from(vals)
            new_vals.splice(index, 1)
            return new_vals
        })
        rates_store.update(vals => {
            let newVals = []
            vals.forEach((value, i) => {
                if (i !== index) {
                    newVals.push([...value])
                }
            })
            return newVals
        })
    }

    function removeExpLevel(index: number) {
        exp_level_store.update(vals => {
            let new_vals = Array.from(vals)
            new_vals.splice(index, 1)
            return new_vals
        })
        rates_store.update(vals => {
            let newVals = []
            for (const row of vals) {
                let newRow = Array.from(row)
                newRow.splice(index, 1)
                newVals.push(newRow)
            }
            return newVals
        })
    }

</script>

<div class="container">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header">
                    Job Level
                </div>
                <form>
                    <ul class="list-group list-group-flush">
                        {#each $job_level_store as level, index (level.id)}
                        <li class="list-group-item">
                            <div class="input-group">
                                <input type="text" class="form-control" bind:value={level.val} />
                                <button class="btn btn-outline-secondary" type="button" on:click={() => removeJobLevel(index)}>
                                    <i class="fa fa-xmark" aria-hidden="true"></i>
                                </button>
                            </div>
                        </li>
                        {/each}
                    </ul>
                </form>
                <div class="card-body">
                    <button type="button" class="btn btn-primary" on:click={() => addJobLevel()}>Add level</button>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card">
                <div class="card-header">
                    Experience Level
                </div>
                <form>
                    <ul class="list-group list-group-flush">
                        {#each $exp_level_store as level, index (level.id)}
                        <li class="list-group-item">
                            <div class="input-group">
                                <input type="text" class="form-control" bind:value={level.val} />
                                <button class="btn btn-outline-secondary" type="button" on:click={() => removeExpLevel(index)}>
                                    <i class="fa fa-xmark" aria-hidden="true"></i>
                                </button>
                            </div>
                        </li>
                        {/each}
                    </ul>
                </form>
                <div class="card-body">
                    <button type="button" class="btn btn-primary" on:click={() => addExpLevel()}>Add level</button>
                </div>
            </div>
        </div>
    </div>
    <!-- TODO(joshuata): Heading disappears if there is only a single experience level -->
    <div class="row">
        <form>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col" class="col-1"></th>
                        {#each $exp_level_store as level (level.id)}
                        <th scope="col" class="col-1">{level.val}</th>
                        {/each}
                    </tr>
                </thead>
                <tbody>
                    {#each $job_level_store as jlevel, jindex (jlevel.id)}
                    <tr>
                        <th scope="row">{jlevel.val}</th>
                        {#each $exp_level_store as elevel, eindex (elevel.id)}
                        <td>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                </div>
                                <input type="text" inputmode="numeric" class="form-control" bind:value={$rates_store[jindex][eindex]} />
                            </div>
                        </td>
                        {/each}
                    </tr>
                    {/each}
                </tbody>
            </table>
        </form>
    </div>
</div>
