import 'vite/modulepreload-polyfill';
import * as Sentry from "@sentry/browser";


Sentry.init({
    dsn: "https://88759f0670a0439b9c61a0d8b7d93806@o1201436.ingest.sentry.io/4504091334148096",
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        Sentry.replayIntegration(),
        Sentry.browserTracingIntegration(),
        Sentry.feedbackIntegration({
            buttonLabel: "Feedback",
            formTitle: "Report a bug or request a feature",
            submitButtonLabel: "Submit feedback",
            messagePlaceholder: "What's the bug? What did you expect? How can the app be improved?",
            colorScheme: "light",
        })
    ]
});

import '../css/main';

import 'bootstrap';
import './dynamic_forms';
import './dashboard_animation';
import RatesForm from './components/RatesForm.svelte';

window.addEventListener('DOMContentLoaded', () => {
    const rates_form_div = document.getElementById("rates-form")
    if (rates_form_div) {
        new RatesForm({
            target: rates_form_div,
        })
    }
})
